import {
  Box,
  Button,
  // CircularProgress,
  // OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TitleText from "../../../../Components/TitleText";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { CommonButtonstyle } from "../../../../Components/CommonCss";
// import EmailInput from "../../../../Components/Inputs/EmailInput";
// import { useDispatch } from "react-redux";
// import {
//   Addcategorie,
//   Getallcategorie,
// } from "../../../../Redux/Slices/Ebooks/Ebooks.action";
import Add_Edit_Ebooklist from "../../../../Components/Popups/Add_Edit_Ebooklist";
import TostContainer from "../../../../Components/Alerts/TostContainer";
// import {
//   errortost,
//   sucesstost,
// } from "../../../../Components/Alerts/CustomAlert";
const Ebooks = () => {
  let commonfont = {
    fontStyle: "Poppins",
    fontSize: "20px",
    fontWeight: "550",
    lineHeight: "100%",
  };
  let links = [{ id: 1, name: "EBooklist", path: "/dashboard/ebooks" }];
  const location = useLocation();
  const currentPath = location.pathname;
  // let [Categorievalue, setcategorivalue] = useState("");
  // let [loading, setloading] = useState(false);
  // let dispatch = useDispatch();
  let navigate = useNavigate();
  // const handleInputvalue = (e) => {
  //   setcategorivalue(e.target.value);
  // };
  // let AddnewCategori = () => {
  //   if (Categorievalue.trim() === "") {
  //     errortost("Please enter a category");
  //     return;
  //   }
  //   let catdata = new FormData();
  //   catdata.append("cat_name", Categorievalue);
  //   setloading(true);
  //   dispatch(Addcategorie(catdata))
  //     .unwrap()
  //     .then((result) => {
  //       if (result?.s === 1) {
  //         sucesstost("Category Added!");
  //         setloading(false);
  //         setcategorivalue(""); // Clear the input field
  //         dispatch(Getallcategorie());
  //       } else {
  //         errortost("Something went wrong!");
  //         setloading(false);
  //       }
  //     });
  // };
  // -------- Add New Ebook ----------
  let [isEbookopen, setisEbookopen] = useState(false);

  let closeEbook = () => {
    setisEbookopen(false);
  };

  let openEbook = () => {
    setisEbookopen(true);
  };

  let popuptitle = "Add New Ebook";

  return (
    <>
      <Add_Edit_Ebooklist
        modalcontrols={{ isEbookopen, closeEbook, popuptitle }}
      />
      {currentPath == "/dashboard/ebooks" ? <TitleText title={"Ebooks"} /> : ""}
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: "20px",
          }}
        >
          {currentPath == "/dashboard/ebooks" ? (
            <Box sx={{ display: "flex", gap: "20px" }}>
              {links?.map((li, i) => (
                <Box>
                  <Link to={li.path} style={{ textDecoration: "none" }}>
                    <Typography
                      sx={{
                        ...commonfont,
                        cursor: "pointer",
                        fontSize: "18px",
                        position: "relative",
                        textDecoration: "none",
                        borderBottom:
                          currentPath == li?.path
                            ? "3px solid #F68582"
                            : "3px solid transparent",
                        color: currentPath == li?.path ? "black" : "gray",
                        "&:hover": {
                          textDecoration: "none", // Remove the default underline
                          borderBottom: "3px solid #F68582", // Add the color as an underline
                          transition: "border-bottom 0.2s ease-in-out", // Add transition for the border-bottom
                        },
                      }}
                    >
                      {li?.name}
                    </Typography>
                  </Link>
                </Box>
              ))}
            </Box>
          ) : (
            ""
          )}

          {currentPath == "/dashboard/ebooks" ? (
            <Button
              onClick={() =>
                // openEbook()

                navigate("/dashboard/ebooks/add_edit")
              }
              sx={{ ...CommonButtonstyle, width: "15%" }}
            >
              Add More
            </Button>
          ) : (
            ""
            // <Box
            //   sx={{
            //     width: "40%",
            //     alignItems: "center",
            //     display: "flex",
            //     justifyContent: "space-between",
            //     gap: "3%",
            //   }}
            // >
            //   <Box sx={{ width: "60%" }}>

            //     <OutlinedInput
            //       id={"vhgbftbnfkgsvf"}
            //       name="email"
            //       type="text"
            //       aria-describedby="outlined-weight-helper-text"
            //       placeholder={"Enter Categorie"}
            //       onChange={handleInputvalue} // Ensure this is correctly updating the state
            //       value={Categorievalue}
            //       sx={{
            //         width: "100%",
            //         color: "black",
            //         fontSize: "20px",
            //         borderRadius: "8px",
            //         fontFamily: "Montserrat",
            //         borderColor: "  #DADADA",
            //         outline: "none",
            //         "& .MuiFilledInput-root": {
            //           background: "var(--white-100, #FFF)",
            //           outline: "none",
            //         },
            //         "&:hover": {
            //           "& fieldset": {
            //             outline: "none",
            //           },
            //           "& .MuiInputBase-input": {
            //             color: "black", // Change the color to black on hover
            //           },
            //         },
            //         "& .MuiInputBase-input": {
            //           color: "black",
            //           fontSize: { xs: "13px", md: "16px" },
            //           fontWeight: 400,
            //           lineHeight: "24px",
            //           border: "2px solid var(--Button-Color, #F68582)",
            //           padding: "8px 15px",
            //           background: "var(--white-100, #FFF)",
            //           outline: "none",
            //           borderRadius: "10px",
            //           "&::placeholder": {
            //             color: "rgba(41, 45, 50, 0.50)",
            //             background: "var(--white-100, #FFF)",
            //             opacity: 0.5,
            //             fontSize: { xs: "13px", md: "16px" },
            //             fontWeight: 400,
            //             lineHeight: "10px",
            //             outline: "none",
            //           },
            //         },
            //         "&:focus": {
            //           outline: "none",
            //           boxShadow: "none",
            //           color: "rgba(41, 45, 50, 0.50)",
            //         },
            //         "&.Mui-focused fieldset": {
            //           outline: "none",
            //           border: "solid red",
            //         },
            //       }}
            //       inputProps={{ autoComplete: "off" }}
            //     />
            //   </Box>

            //   <Button
            //     onClick={AddnewCategori}
            //     sx={{ ...CommonButtonstyle, width: "40%" }}
            //   >
            //     {loading ? (
            //       <CircularProgress sx={{ color: "#fff" }} />
            //     ) : (
            //       <Typography> Add More </Typography>
            //     )}
            //   </Button>
            // </Box>
          )}
        </Box>
      </Box>

      <Box>
        <Outlet />
      </Box>
    </>
  );
};

export default Ebooks;
