import React, { useEffect, useState } from "react";
import { Box, Button, Input, Typography } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  AddEbookVideo,
  GetEbookDetail,
} from "../../../../Redux/Slices/Ebooks/Ebooks.action";
import { EboksVideourl } from "../../../../Apis/Api";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import toast, { Toaster } from "react-hot-toast";
import DeleteEbookVideo from "../../../../Components/Popups/DeleteEbookVideo";
import ActionButton from "../../../../Components/Popups/PopupComponent/ActionButton";

const AddEditEbookVideos = () => {
  const [videoFile, setVideoFile] = useState(null);
  const [videoThumbnail, setVideoThumbnail] = useState(null); // Thumbnail as Blob
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = location?.state?.id;
  const [AddedVideos, SetAddedVideos] = useState({});
  let [deletevideoData, setdeletevideoData] = useState(null);
  let [isdeleteEbook, setisdeleteebook] = useState();

  let openebooklistdelete = (data) => {
    setdeletevideoData(data);
    setisdeleteebook(true);
  };
  let closedeleteebook = () => {
    setisdeleteebook(false);
  };

  const validateFile = (file) => {
    const MAX_SIZE = 500 * 1024 * 1024; // 500MB max
    if (!file.type.startsWith("video/")) {
      toast.error("Invalid file type. Please upload a video file.");
      return false;
    }
    const unsupportedFormats = ["video/avi"];
    if (unsupportedFormats.includes(file.type)) {
      toast.error(
        "AVI format is not supported. Please upload a different video format."
      );
      return false;
    }
    if (file.size > MAX_SIZE) {
      toast.error("File size exceeds 500MB.");
      return false;
    }
    return true;
  };

  const captureThumbnail = (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      const canvas = document.createElement("canvas");

      // Important: Set these attributes for iOS Safari
      video.playsInline = true;
      video.muted = true;
      video.autoplay = true;

      // Create object URL after setting attributes
      video.src = URL.createObjectURL(file);
      video.crossOrigin = "anonymous";

      // Handle video loading
      video.onloadedmetadata = () => {
        // Set video dimensions
        video.width = video.videoWidth;
        video.height = video.videoHeight;

        // Start playing (required for Safari)
        video
          .play()
          .then(() => {
            // Wait a bit to ensure frame is loaded
            setTimeout(() => {
              // Set canvas dimensions
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;

              // Draw and capture frame
              const context = canvas.getContext("2d");
              context.drawImage(video, 0, 0, canvas.width, canvas.height);

              // Stop video playback
              video.pause();

              // Convert to blob
              canvas.toBlob(
                (blob) => {
                  if (blob) {
                    // Clean up
                    URL.revokeObjectURL(video.src);
                    resolve(blob);
                  } else {
                    reject(new Error("Failed to generate thumbnail blob."));
                  }
                },
                "image/png",
                1.0
              );
            }, 500); // Add small delay to ensure frame is rendered
          })
          .catch((error) => {
            reject(new Error(`Video play failed: ${error.message}`));
          });
      };

      video.onerror = () => {
        URL.revokeObjectURL(video.src);
        reject(new Error("Error loading video."));
      };
    });
  };

  const handleVideoFileChange = async (event) => {
    const file = event?.target?.files[0];
    if (!file) return;
    if (!validateFile(file)) return;
    try {
      setVideoFile(file); // Set the video file
      const thumbnailBlob = await captureThumbnail(file);
      setVideoThumbnail(thumbnailBlob); // Save thumbnail as Blob
    } catch (error) {
      toast.error("Error capturing video thumbnail.");
      console.error(error);
    }
  };

  const handleAddVideo = async () => {
    if (!videoFile || !videoThumbnail) {
      toast.error("Please select a video file and generate a thumbnail.");
      return;
    }
    if (!id) {
      toast.error("Missing ID from the route state.");
      return;
    }
    try {
      setLoading(true);
      const videoData = new FormData();
      videoData.append("video_file", videoFile); // Video file
      videoData.append("video_thumb", videoThumbnail, "thumbnail.png"); // Thumbnail blob with filename
      videoData.append("ebook_id", id);
      await dispatch(AddEbookVideo(videoData))
        .unwrap()
        .then((result) => {
          if (result?.s == 1) {
            getvideodata();
            toast.success("Video uploaded successfully!");
            setVideoFile(null);
            setVideoThumbnail(null);
          } else {
            toast.error("Failed to upload video.");
          }
        });
    } catch (error) {
      console.error("API Error:", error);
      toast.error("An error occurred while uploading the video.");
    } finally {
      setLoading(false);
    }
  };
  const handleRemoveFile = () => {
    setVideoFile(null);
    setVideoThumbnail(null);
  };

  let getvideodata = () => {
    if (location?.state?.id) {
      dispatch(GetEbookDetail(location?.state?.id))
        .unwrap()
        .then((result) => {
          SetAddedVideos(result?.r);
        });
    } else {
      SetAddedVideos({});
    }
  };
  useEffect(() => {
    getvideodata();
  }, [location?.state?.id]);

  let getback = () => {
    console.log("call");

    navigate("/dashboard/ebooks/add_edit", {
      state: {
        id: location?.state?.id,
      },
    });
  };
  return (
    <>
      {isdeleteEbook && (
        <DeleteEbookVideo
          modalcontrols={{
            isdeleteEbook,
            closedeleteebook,
            deletevideoData,
            getvideodata,
          }}
        />
      )}
      <Toaster position="top-right" reverseOrder={false} />
      <Box
        sx={{
          padding: "30px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",

              alignItems: "center",

              mb: "10px",
            }}
          >
            <ArrowBackIosIcon
              onClick={() => navigate("/dashboard/ebooks")}
              sx={{ height: "20px", width: "20px", cursor: "pointer" }}
            />
            <Typography
              onClick={() => navigate("/dashboard/ebooks")}
              sx={{
                fontSize: "20px",
                fontFamily: "Poppins",
                fontWeight: "600",
                lineHeight: "120%",
                cursor: "pointer",
              }}
            >
              Ebook List
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",

              alignItems: "center",
              padding: "0px 0px",

              mb: "10px",
            }}
          >
            <ArrowBackIosIcon
              onClick={() =>
                navigate("/dashboard/ebooks/add_edit/", {
                  state: {
                    id: location?.state?.id,
                  },
                })
              }
              sx={{ height: "20px", width: "20px", cursor: "pointer" }}
            />
            <Typography
              onClick={() =>
                navigate("/dashboard/ebooks/add_edit/", {
                  state: {
                    id: location?.state?.id,
                  },
                })
              }
              sx={{
                fontSize: "20px",
                fontFamily: "Poppins",
                fontWeight: "600",
                lineHeight: "120%",
                cursor: "pointer",
              }}
            >
              Details{" "}
            </Typography>
          </Box>
        </Box>

        <Typography variant="h6" sx={{ color: "#000" }}>
          Upload Video File in{" "}
          <span style={{ color: "rgba(246, 133, 130,1)" }}>
            {AddedVideos?.title}
          </span>{" "}
        </Typography>

        {/* Display Video File and Thumbnail */}
        <Box sx={{ width: "30%", margin: "auto" }}>
          {videoFile ? (
            <Box
              sx={{
                boxShadow: 3,
                borderRadius: "20px",
                padding: "20px",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  border: "2.5px dotted rgba(246, 133, 130,1)",
                  borderRadius: "20px",
                  width: "100%",
                  height: "30vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {/* Thumbnail Preview */}
                {videoThumbnail && (
                  <img
                    src={URL.createObjectURL(videoThumbnail)} // Convert Blob to URL for preview
                    alt="Video Thumbnail"
                    width="100%"
                    height="100%"
                    style={{ borderRadius: "20px", objectFit: "cover" }}
                  />
                )}
                {/* File Name */}
              </Box>
              <Typography
                sx={{
                  color: "#000",
                  fontSize: "14px",
                  textAlign: "center",
                  pt: "10px",
                }}
              >
                {videoFile.name}
              </Typography>
              {loading ? (
                ""
              ) : (
                <Button
                  disabled={loading}
                  onClick={handleRemoveFile}
                  sx={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    backgroundColor: "white",
                    paddingY: "10px",
                    border: "2.5px solid rgba(246, 133, 130,1)",
                    cursor: "pointer",
                    borderRadius: "20px",
                    "&:hover": { backgroundColor: "rgba(246, 133, 130,1)" },
                  }}
                >
                  <DeleteOutlineIcon
                    sx={{
                      color: "rgba(246, 133, 130,1)",
                      color: "black",
                    }}
                  />
                </Button>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                boxShadow: 3,
                borderRadius: "20px",
                padding: "20px",
              }}
            >
              <Input
                type="file"
                accept="video/*"
                id="video-file-input"
                onChange={handleVideoFileChange}
                style={{ display: "none" }}
              />
              <label htmlFor="video-file-input">
                <Box
                  sx={{
                    border: "2.5px dotted rgba(246, 133, 130,1)",
                    borderRadius: "20px",
                    width: "100%",
                    height: "30vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <LibraryAddIcon
                    sx={{ color: "gray", height: "5vh", width: "5vw" }}
                  />
                  <Typography sx={{ width: "50%", textAlign: "center" }}>
                    Click here to browse{" "}
                    <span
                      style={{
                        color: "rgba(246, 133, 130,1)",
                        fontWeight: "600",
                      }}
                    >
                      video{" "}
                    </span>{" "}
                    File
                  </Typography>
                </Box>
              </label>
            </Box>
          )}
        </Box>

        <Button
          variant="contained"
          onClick={handleAddVideo}
          disabled={loading || !videoFile}
          sx={{
            width: "15%",
            margin: "auto",
            backgroundColor: "rgba(246, 133, 130, 0.5)",
            color: "#000",
            "&:hover": {
              background: "rgba(246, 133, 130,1) ,",
              color: "white",
            },
            borderRadius: "20px",
            textTransform: "capitalize",
          }}
        >
          {loading ? "Uploading..." : "Upload Video"}
        </Button>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          {AddedVideos?.ebooks_videos?.map((el) => (
            <Box
              sx={{
                padding: "10px",
                gap: "10px",
                borderRadius: "20px",
                border: "1px solid red",
                width: "20%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: "100%" }}
                src={`${EboksVideourl}/${el?.video_thumb}`}
                alt=""
              />

              <Button
                onClick={() => {
                  openebooklistdelete(el);
                }}
                sx={{
                  backgroundColor: "rgba(246, 133, 130, 0.5)",
                  color: "#000",
                  "&:hover": { background: "rgba(246, 133, 130, 2)" },
                  borderRadius: "5px",
                  textTransform: "capitalize",
                }}
              >
                {" "}
                Remove
              </Button>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "15%",
            m: "auto",
            mt: "20px",
            gap: "10px",
          }}
        >
          <ActionButton title={"Back"} onclick={getback} />
        </Box>
      </Box>
    </>
  );
};

export default AddEditEbookVideos;
