import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { modalstyle } from "./PopupsStyling";
import { EboksVideourl } from "../../Apis/Api";
import { useDispatch } from "react-redux";
import { RemoveEbookVideo } from "../../Redux/Slices/Ebooks/Ebooks.action";
import toast from "react-hot-toast";
const DeleteEbookVideo = ({ modalcontrols }) => {
  let { isdeleteEbook, closedeleteebook, deletevideoData, getvideodata } =
    modalcontrols;
  let dispatch = useDispatch();
  let [loading, setloading] = useState(false);
  let deletethisfile = () => {
    let deleteitemdata = new FormData();
    deleteitemdata.append("id", deletevideoData?.id);
    setloading(true);
    dispatch(RemoveEbookVideo(deleteitemdata))
      .unwrap()
      .then((result) => {
        setloading(false);
        if (result?.s === 1) {
          toast.success("Video Deleted Sucessfully");
          getvideodata();
          closedeleteebook();
        } else {
          toast.error("Something wents wrong please try again !");
          setloading(false);
          closedeleteebook();
        }
      })
  }
  return (
    <>
      <Modal open={isdeleteEbook} onClose={closedeleteebook}>
        <Box
          sx={{
            ...modalstyle,
            borderRadius: "10px",
            width: { xs: "50vw", md: "350px" },
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Poppins",
              display: "flex",
              gap: "5px",
            }}
          >
            Want to Delete this <Typography> Ebook Video </Typography>
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "13px",
              padding: "10px",
              background: "rgba(128, 128, 128, 0.15)",
              borderRadius: "10px",
            }}
          >
            <img
              style={{ width: "100px", height: "80px", borderRadius: "10px" }}
              src={`${EboksVideourl}/${deletevideoData?.video_thumb}`}
              alt="user image"
            />

            <Typography
              sx={{ color: "#000", textAlign: "center" }}
            ></Typography>
          </Box>

          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              onClick={deletethisfile}
              sx={{
                textTransform: "capitalize",
                mt: "10px",
                width: "100%",
                borderRadius: "14px",
                background: "#FF1607",
                color: "#fff",
                padding: "8px",
                height: "50px",
                "&:hover": {
                  background: "#A30000",
                },
              }}
            >
              {loading ? (
                <CircularProgress sx={{ color: "#fff" }} />
              ) : (
                <Typography>Delete</Typography>
              )}
            </Button>
            <Button
              onClick={() => closedeleteebook()}
              sx={{
                textTransform: "capitalize",
                mt: "10px",
                borderRadius: "14px",
                background: "#E8E8E8",
                width: "100%",
                color: "#000",
                padding: "8px",
                "&:hover": {
                  background: "#B0B0B0",
                },
              }}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteEbookVideo;
