import { createSlice } from "@reduxjs/toolkit";
import { AddEbookVideo, AddEbookitem, Addcategorie, DeleteEbookitem, EditEbookitems, GetEbookDetail, GetEbooklist, Getallcategorie, RemoveEbookVideo, UpdateCategory } from "./Ebooks.action";
let initialState = {
  loading: false,
  error: null,
  EbooksCategories: [],
  EbooksList:[],
  EbookDetails:{}
};
const EbooksSlice = createSlice({
  name: "Ebooks",
  initialState,
  extraReducers: (builder) => {
    // --------- Add New Ebook Categories---------
    builder
      .addCase(Addcategorie.pending, (state) => {
        state.loading = true;
      })
      .addCase(Addcategorie.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(Addcategorie.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    
    // --------- Add New Ebook Categories---------
    builder
      .addCase(AddEbookitem.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddEbookitem.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(AddEbookitem.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- Add New Ebook Video---------
    builder
      .addCase(AddEbookVideo.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddEbookVideo.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(AddEbookVideo.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- Remove New Ebook Video---------
    builder
      .addCase(RemoveEbookVideo.pending, (state) => {
        state.loading = true;
      })
      .addCase(RemoveEbookVideo.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(RemoveEbookVideo.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- Edit Ebook Items ---------
    builder
      .addCase(EditEbookitems.pending, (state) => {
        state.loading = true;
      })
      .addCase(EditEbookitems.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(EditEbookitems.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- Get all Ebook Categories---------
    builder
      .addCase(Getallcategorie.pending, (state) => {
        state.loading = true;
      })
      .addCase(Getallcategorie.fulfilled, (state, action) => {
        state.loading = false;
        state.EbooksCategories = action?.payload?.r;
      })
      .addCase(Getallcategorie.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- Get Ebooklist ---------
    builder
      .addCase(GetEbooklist.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetEbooklist.fulfilled, (state, action) => {
        state.loading = false;
        state.EbooksList = action?.payload;
      })
      .addCase(GetEbooklist.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
    // --------- Delete EbookItem ---------
    builder
      .addCase(DeleteEbookitem.pending, (state) => {
        state.loading = true;
      })
      .addCase(DeleteEbookitem.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(DeleteEbookitem.rejected, (state) => {
        state.error = true;
        state.loading = false;
      })

    // --------- Update EbookItem ---------
    builder
      .addCase(UpdateCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateCategory.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(UpdateCategory.rejected, (state) => {
        state.error = true;
        state.loading = false;
      })
    // ---------  Ebook Details ---------
    builder
      .addCase(GetEbookDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetEbookDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.EbookDetails=action?.payload
      })
      .addCase(GetEbookDetail.rejected, (state) => {
        state.error = true;
        state.loading = false;
      })


  },
});

export default EbooksSlice.reducer;
