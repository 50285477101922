import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import logoimg from "../../Utils/Logos/mindfulnesslogo.PNG";
import { sidebarcontent } from "./Sidebar.dto";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import LogoutPopup from "../Popups/LogoutPopup";

const Sidebar = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [activeTab, setActiveTab] = useState(null);
  let location = useLocation().pathname;

  const handleResize = () => {
    if (window.innerWidth <= 600) {
      setSidebarOpen(false);
    } else {
      setSidebarOpen(true);
    }
  };
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleTabHover = (index) => {
    setActiveTab(index);
  };

  const handleTabLeave = () => {
    setActiveTab(null);
  };

  // ------ logout Popup ---------
  let [islogoutopen, setislogoutopen] = useState(false);
  let openlogoutpopup = () => {
    console.log("open popup");
    setislogoutopen(true);
  };
  let closelogout = () => {
    setislogoutopen(false);
  };

  return (
    <>
      <ToastContainer />
      <LogoutPopup modalcontrols={{ islogoutopen, closelogout }} />
      <Box
        sx={{
          background: "#FBFBFB",
          width: isSidebarOpen ? "100%" : "64px",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          transition: "width 0.3s ease",
          boxShadow: "10px 10px 26px 0px rgba(0, 0, 0, 0.10);",
        }}
      >
        <Box
          sx={{
            width: "100%",
            color: "#fff",
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "150px", height: "150px" }}>
            <img
              src={logoimg}
              style={{ width: "100%", height: "100%" }}
              alt="logoimg"
            />
          </Box>
        </Box>

        <Box sx={{ height: "90%", overflow: "auto" }}>
          {sidebarcontent.map((tab, index) => (
            <Link to={tab?.location} style={{ textDecoration: "none" }}>
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  width: "100%",
                  background: "#FBFBFB",
                  border: "0.5px solid transparent",
                  cursor: "pointer",
                  display: isSidebarOpen ? "flex" : "none",
                  padding: "0px 5px",
                  color: "#000",
                  boxSizing: "border-box",
                }}
                onMouseEnter={() => handleTabHover(index)}
                onMouseLeave={handleTabLeave}
              >
                <Box
                  onClick={() => (tab.key ? openlogoutpopup() : "")}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    borderRadius: "10px",
                    padding: "12px 40px",
                    width: "80%",
                    height: "100%",
                    m: "auto",
                    border: "1px solid transparent",
                    background:
                      location === tab.location ||
                      (location == "/dashboard/resources/video" &&
                        tab.location == "/dashboard/resources") ||
                      (location == "/dashboard/resources/blogs" &&
                        tab.location == "/dashboard/resources") ||
                      (location == "/dashboard/ebooks/ebookscategories" &&
                        tab.location == "/dashboard/ebooks") ||
                      (location == "/dashboard/schedules/groupsession" &&
                        tab.location == "/dashboard/schedules") ||
                      (location == "/dashboard/setting/privacypolicy" &&
                        tab.location == "/dashboard/setting") ||
                      (location == "/dashboard/coaches/add_coach" &&
                        tab.location == "/dashboard/coaches") ||
                      (location == "/dashboard/coaches/view_Coach" &&
                        tab.location == "/dashboard/coaches")
                        ? "#F68582"
                        : "",
                    color:
                      location === tab.location ||
                      (location == "/dashboard/resources/video" &&
                        tab.location == "/dashboard/resources") ||
                      (location == "/dashboard/resources/blogs" &&
                        tab.location == "/dashboard/resources") ||
                      (location == "/dashboard/ebooks/ebookscategories" &&
                        tab.location == "/dashboard/ebooks") ||
                      (location == "/dashboard/schedules/groupsession" &&
                        tab.location == "/dashboard/schedules") ||
                      (location == "/dashboard/setting/privacypolicy" &&
                        tab.location == "/dashboard/setting") ||
                      (location == "/dashboard/coaches/add_coach" &&
                        tab.location == "/dashboard/coaches") ||
                      (location == "/dashboard/coaches/view_Coach" &&
                        tab.location == "/dashboard/coaches")
                        ? "#fff"
                        : "#000",
                    transition: "border 0.2s ease,",
                    "&:hover": {
                      border: "1px solid #F68582",
                    },
                  }}
                >
                  <Box sx={{ width: "24px", height: "24px" }}>
                    <img
                      src={
                        location === tab.location ||
                        (location == "/dashboard/resources/video" &&
                          tab.location == "/dashboard/resources") ||
                        (location == "/dashboard/resources/blogs" &&
                          tab.location == "/dashboard/resources") ||
                        (location == "/dashboard/ebooks/ebookscategories" &&
                          tab.location == "/dashboard/ebooks") ||
                        (location == "/dashboard/schedules/groupsession" &&
                          tab.location == "/dashboard/schedules") ||
                        (location == "/dashboard/setting/privacypolicy" &&
                          tab.location == "/dashboard/setting") ||
                        (location == "/dashboard/coaches/add_coach" &&
                          tab.location == "/dashboard/coaches") ||
                        (location == "/dashboard/coaches/view_Coach" &&
                          tab.location == "/dashboard/coaches")
                          ? tab.Activelogo
                          : tab.logo
                      }
                      style={{ width: "24px", height: "24px" }}
                      alt="logos"
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      lineHeight: "120%",
                      fontWeight: "600",
                      fontSize: "16px",
                      width: "200px",
                    }}
                  >
                    {tab.Title}
                  </Typography>
                </Box>
              </Box>
            </Link>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default Sidebar;
