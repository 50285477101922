import {
  Box,
  Button,
  Input,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  AddEbookitem,
  EditEbookitems,
  Getallcategorie,
  GetEbookDetail,
  GetEbooklist,
} from "../../../../Redux/Slices/Ebooks/Ebooks.action";

import { Ebokscoverurl } from "../../../../Apis/Api";
import ActionButton from "../../../../Components/Popups/PopupComponent/ActionButton";
import { useLocation, useNavigate } from "react-router-dom";

import toast, { Toaster } from "react-hot-toast";
let inputstyle = {
  width: "100%",
  height: "50px",
  borderRadius: "10px",
  border: "1px solid #F68582",
  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
};

let Inputboxstyle = {
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
};

let Lablestyle = {
  fontSize: "15px",
  mt: "10px",
  fontWeight: "500",
};

const AddEditEbook = () => {
  let [editData, seteditData] = useState(null);

  let location = useLocation();
  // let [RemovedVideoFileListId, setRemovedVideoFileListId] = useState([]);
  const [title, settitle] = useState("");

  const [author_name, setauthor_name] = useState("");
  const [cat_type, setcat_type] = useState("");

  const [description, setdescription] = useState("");

  const [cover_img, setcover_img] = useState(null);

  const [file, setfile] = useState(editData ? editData?.file : null);

  let categorydata = useSelector((store) => store?.Ebooks);
  let [loading, setloading] = useState(false);
  let [editloading, seteditloading] = useState(false);
  let dispatch = useDispatch();
  let [previewimg, setpreviewimg] = useState(null);
  let [newfilename, setnewfilename] = useState("");
  const [formErrors, setFormErrors] = useState({
    title: "",
    author_name: "",
    price: "",
    cat_type: "",
    description: "",
    cover_img: "",
    file: "",
  });

  const resetForm = () => {
    settitle("");
    setauthor_name("");

    setcat_type("");
    setdescription("");
    setcover_img(null);
    setfile(null);

    setpreviewimg(null);
    setFormErrors({
      title: "",
      author_name: "",
      cat_type: "",
      description: "",
      cover_img: "",
      file: "",
    });
  };
  const validateForm = () => {
    const titleValue = title || "";
    const authorNameValue = author_name || "";
    const catTypeValue = cat_type || "";
    const descriptionValue = description || "";
    const coverImgValue = cover_img || "";
    const fileValue = file || "";
    // const videoFileValue = video_files || ""; // Validate video file
    const errors = {
      title: titleValue === "" ? "Title is required." : "",
      author_name: authorNameValue === "" ? "Author name is required." : "",

      cat_type: catTypeValue === "" ? "Category type is required." : "",
      description: descriptionValue === "" ? "Description is required." : "",
      cover_img:
        !location?.state?.id && coverImgValue === ""
          ? "Cover image is required."
          : "",
          file: location?.state?.id 
          ? "" // If `id` is present, no validation message is needed
          : fileValue 
              ? "" // If `fileValue` is present when `id` is absent, no validation message
              : "File is required." 
    };
    setFormErrors(errors);
    return Object.values(errors).every((error) => error === "");
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormErrors({ ...formErrors, [name]: false });
    if (name === "title") {
      settitle(value);
    } else if (name === "author_name") {
      setauthor_name(value);
    } else if (name === "cat_type") {
      setcat_type(value);
    } else if (name === "description") {
      setdescription(value);
    }
  };

  const handleFileChange = (event, fileType) => {
    const file = event.target.files[0];
    if (!file) {
      console.log(`Please select a file for ${fileType}`);
      setFormErrors({ ...formErrors, [fileType]: true });
      return;
    }
    if (fileType === "image" && !file.type.startsWith("image/")) {
      setFormErrors({ ...formErrors, [fileType]: true });
      return;
    }
    if (fileType === "file" && !file.type.startsWith("application/pdf")) {
      setFormErrors({ ...formErrors, [fileType]: true });
      toast.error("Please select correct file type");
      return;
    }
    setFormErrors({ ...formErrors, [fileType]: false });
    if (fileType === "image") {
      const objectURL = URL.createObjectURL(file);
      setpreviewimg(objectURL);
      setcover_img(file);
      console.log(file);
    } else if (fileType === "file" && file.type.startsWith("application/pdf")) {
      setfile(file);
      setnewfilename(file);
    }
  };
  const HandeldEbookButton = () => {
    const isValid = validateForm();
    if (isValid) {
      const formData = new FormData();
      if (editData && location?.state?.id) {
        if (title !== editData?.title) {
          formData.append("title", title);
        }
        if (cat_type !== editData?.cat_type) {
          formData.append("cat_type", cat_type);
        }
        if (author_name !== editData?.author_name) {
          formData.append("author_name", author_name);
        }
        if (description !== editData?.description) {
          formData.append("description", description);
        }
        if (cover_img !== editData?.cover_img) {
          formData.append("cover_img", cover_img);
        }
        if (file) {
          formData.append("file", file);
        }
        formData.append("id", editData?.id);
        seteditloading(true);
        let onlyHasId = true; // Flag to check if only 'id' exists
        for (let pair of formData.entries()) {
          if (pair[0] !== "id") {
            onlyHasId = false; // Found another key besides 'id'
            break;
          }
        }
        if (onlyHasId) {
          navigate("./videos", {
            state: {
              id: editData?.id,
            },
          });
          return;
        } else {
          dispatch(EditEbookitems(formData))
            .unwrap()
            .then((result) => {
              if (result.s === 1) {
                seteditloading(false);
                dispatch(GetEbooklist({ count: 0, row_count: 10 }));
                toast.success("Ebook Edited Sucessfully !");
                navigate("./videos", {
                  state: {
                    id: location?.state?.id,
                  },
                });
                resetForm();
              } else {
                seteditloading(false);
                dispatch(GetEbooklist({ count: 0, row_count: 10 }));
                toast.error("Something Wents wrong .");
                resetForm();
              }
            });
        }
      } else {
        formData.append("title", title);
        formData.append("author_name", author_name);
        formData.append("cat_type", cat_type);
        formData.append("cover_img", cover_img);
        formData.append("description", description);
        formData.append("file", file);
        setloading(true);

        dispatch(AddEbookitem(formData))
          .unwrap()
          .then((result) => {
            if (result.s === 1) {
              setloading(false);
              toast.success("Ebook Added Sucessfully !");
              dispatch(GetEbooklist({ count: 0, row_count: 10 }));
              navigate("./videos", {
                state: {
                  id: result?.r?.id,
                },
              });
              resetForm();
              setnewfilename("");
            } else {
              setloading(false);
              toast.error("Something Wents wrong .");
              resetForm();
              dispatch(GetEbooklist({ count: 0, row_count: 10 }));
            }
          });
      }
    }
  };

  let navigate = useNavigate();

  useEffect(() => {
    dispatch(Getallcategorie());
    if (location?.state?.id) {
      dispatch(GetEbookDetail(location?.state?.id))
        .unwrap()
        .then((result) => {
          console.log(result);
          seteditData(result?.r);
        });
    } else {
      seteditData(null);
    }
  }, []);

  useEffect(() => {
    if (editData) {
      settitle(editData?.title || "");
      setauthor_name(editData?.author_name || "");
      setcat_type(editData?.cat_type || "");
      setdescription(editData?.description || "");
      setcover_img(editData?.cover_img || "");
    }
  }, [editData]);

  console.log(formErrors);

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          padding: "20px 0px",
          cursor: "pointer",
        }}
      >
        <ArrowBackIosIcon
          onClick={() => navigate("/dashboard/ebooks")}
          sx={{ height: "20px", width: "20px", cursor: "pointer" }}
        />
        <Typography
          onClick={() => navigate("/dashboard/ebooks")}
          sx={{
            fontSize: "20px",
            fontFamily: "Poppins",
            fontWeight: "600",
            lineHeight: "120%",
            cursor: "pointer",
          }}
        >
          Ebook List
        </Typography>
      </Box>
      <Box
        sx={{
          borderRadius: "10px",
          width: "90%",
          m: "auto",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          boxSizing: "border-box",
          overflow: "hidden",
        }}
      >
        <Box sx={{ height: "80vh", overflow: "auto", boxSizing: "border-box" }}>
          {/* --------- Ebooks Title ------- */}
          <Box sx={{ ...Inputboxstyle }}>
            <InputLabel
              id="demo-select-small-label"
              ls
              sx={{
                color: formErrors.title ? "red" : "#000",
              }}
            >
              Enter Ebooks Title
            </InputLabel>
            <OutlinedInput
              sx={{
                ...inputstyle,
              }}
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              value={title?.trimStart()}
              placeholder=" Title"
              onChange={handleChange}
              name="title"
            />
          </Box>

          {/* --------- Author Name ------- */}
          <Box sx={{ ...Inputboxstyle }}>
            <InputLabel
              id="demo-select-small-label"
              sx={{
                ...Lablestyle,
                color: formErrors.author_name ? "red" : "#000",
              }}
            >
              Enter Author name
            </InputLabel>
            <OutlinedInput
              sx={{
                ...inputstyle,
              }}
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              placeholder="Author name"
              value={author_name?.trimStart()}
              onChange={handleChange}
              name="author_name"
            />
          </Box>
          {/* ----- Select Category ------ */}

          <Box sx={{ ...Inputboxstyle }}>
            <InputLabel
              id="demo-select-small-label"
              sx={{
                ...Lablestyle,
                color: formErrors.cat_type ? "red" : "#000",
              }}
            >
              Select Category
            </InputLabel>
            <Select
              sx={{
                width: "100%",
                height: "50px", // Adjust the height as needed
                borderRadius: "10px",
                border: "1px solid #F68582",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
              }}
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={cat_type}
              onChange={handleChange}
              name="cat_type"
              placeholder="Select Category"
            >
              <MenuItem value="">Select Category</MenuItem>
              {categorydata?.EbooksCategories.map((el) => (
                <MenuItem value={el?.id}> {el?.cat_name} </MenuItem>
              ))}
            </Select>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              boxSizing: "border-box",
            }}
          >
            <InputLabel
              id="demo-select-small-label"
              sx={{
                ...Lablestyle,
                color: formErrors.description ? "red" : "#000",
              }}
            >
              Description
            </InputLabel>
            <TextField
              value={description?.trimStart()}
              onChange={handleChange}
              name="description"
              id="filled-multiline-static"
              sx={{
                width: "100%",
                borderRadius: "10px",
                boxSizing: "border-box",
                border: "1px solid #F68582",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
              }}
              placeholder="Description"
              multiline
              rows={4}
            />
          </Box>

          <Box sx={{ ...Inputboxstyle }}>
            <InputLabel
              id="demo-select-small-label"
              sx={{
                ...Lablestyle,
                color: formErrors.cover_img ? "red" : "#000",
              }}
            >
              Upload Book Cover Image
            </InputLabel>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid #F68582",
                padding: "10px",
                borderRadius: "10px",
                boxSizing: "border-box",
              }}
            >
              {previewimg ? (
                <img
                  style={{ width: "150px", height: "100%" }}
                  src={previewimg}
                  alt="cover_img"
                />
              ) : editData ? (
                <Box>
                  <img
                    style={{ width: "150px", height: "100%" }}
                    src={`${Ebokscoverurl}/${editData.cover_img}`}
                    alt=""
                  />
                </Box>
              ) : (
                <Typography sx={{ color: "#000" }}>Choose Image </Typography>
              )}
              <Input
                type="file"
                id="image-cover-file-input"
                style={{ display: "none" }}
                onChange={(event) => handleFileChange(event, "image")}
              />
              <label htmlFor="image-cover-file-input">
                <Button
                  variant="contained"
                  component="span"
                  sx={{
                    background: "rgba(246, 133, 130, 0.50)",
                    textDecoration: "capitalize",
                    color: "#000",
                    height: "30px",
                    borderRadius: "10px",
                    transition: "background 0.3s ease", // Add a smooth transition for the background change
                    "&:hover": {
                      background: "rgba(246, 133, 130, 0.70)", // Slightly darker color on hover
                    },
                  }}
                >
                  <Typography sx={{ textTransform: "capitalize" }}>
                    Browse
                  </Typography>
                </Button>
              </label>
            </Box>
          </Box>

          <Box sx={{ ...Inputboxstyle }}>
            <InputLabel
              id="demo-select-small-label"
              sx={{
                ...Lablestyle,
                color: formErrors.file ? "red" : "#000",
              }}
            >
              Upload File
            </InputLabel>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid #F68582",
                padding: "10px",
                borderRadius: "10px",
              }}
            >
              <Typography noWrap style={{ color: "#000", width: "50%" }}>
                {newfilename ? (
                  <Typography>{newfilename?.name}</Typography>
                ) : editData ? (
                  <Box>{editData.file}</Box>
                ) : (
                  <Typography> Upload file </Typography>
                )}
              </Typography>
              <Input
                type="file"
                id="file-cover-file-input"
                style={{ display: "none", boxSizing: "border-box" }}
                onChange={(event) => handleFileChange(event, "file")}
              />

              <label htmlFor="file-cover-file-input">
                <Button
                  variant="contained"
                  component="span"
                  sx={{
                    background: "rgba(246, 133, 130, 0.50)",
                    textDecoration: "capitalize",
                    color: "#000",
                    height: "30px",
                    borderRadius: "10px",
                    transition: "background 0.3s ease", // Add a smooth transition for the background change
                    "&:hover": {
                      background: "rgba(246, 133, 130, 0.70)", // Slightly darker color on hover
                    },
                  }}
                >
                  <Typography sx={{ textTransform: "capitalize" }}>
                    Browse
                  </Typography>
                </Button>
              </label>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "15%",
              m: "auto",
              mt: "20px",
              gap: "10px",
            }}
          >
            <ActionButton
              title={"Save and Next "}
              loading={loading || editloading}
              onclick={HandeldEbookButton}
            />
          </Box>
        </Box>
      </Box>
      {/* </Modal> */}
    </>
  );
};

export default AddEditEbook;
