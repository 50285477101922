export let Baseurl="https://api.theartofmindfulliving.com/api"
export let getUserdata=JSON.parse(localStorage.getItem("m_authdata"))
export let imagebaseurl="https://api.theartofmindfulliving.com/public/upload/profile_img/"
export let iconbaseurl="https://api.theartofmindfulliving.com/public/upload/icons"
 export let audiocoverurl="https://api.theartofmindfulliving.com/public//upload/audio_cover_img/"
 export let videocoverurl="https://api.theartofmindfulliving.com/public/upload/video_thumb/"
 export let blogscoverurl="https://api.theartofmindfulliving.com/public/upload/blog_cover_img/"

 export let Ebokscoverurl="https://api.theartofmindfulliving.com/public/upload/ebooks_category_img/"
 export let EboksVideourl="https://api.theartofmindfulliving.com/public/upload/ebooks_video_file/"

export let groupe_scoverurl="https://api.theartofmindfulliving.com/public/upload/group_sessions/"
export let User_Profileimage="https://api.theartofmindfulliving.com/public/upload/profile_img/"

 
export let Coach_imageUrl="https://api.theartofmindfulliving.com/public/upload/coach_img"

 